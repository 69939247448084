import { ContentBlock, Typography } from '@/components';
import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useStyles } from './styles';
import { SearchForm, SearchCustomerData } from './SearchForm';
import { useNavigate } from 'react-router-dom';
import { getCustomerProvision, getFallbackCustomerList } from '@/api';
import {
  AlertContext,
  AppContext,
  AssetsContext
} from '@/GlobalProvider/GlobalProvider';
import { useIframeMessageHandler } from '@/utils';
import { SearchByIdForm } from './SearchByIdForm';
import { CustomerAccounts } from '@/constants';
import * as internal from 'stream';

const getTitle = (customer_account_handle: string) => {
  switch (customer_account_handle) {
    case CustomerAccounts.EmailOnly:
      return 'Search by email address';
    case CustomerAccounts.PhoneOnly:
      return 'Search by phone number';
    case CustomerAccounts.FirstNameOnly:
      return 'Search by first name';
    case CustomerAccounts.LastNameOnly:
      return 'Search by last name';
    case CustomerAccounts.ThirdPartyAccountId:
      return 'Search by Subject ID';
    default:
      return 'Search by phone number, email, first name, or last name';
  }
};

interface Result {
  id: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  created?: string;
  dateOfBirth?: string;
  gender?: string;
  height?: number;
  weight?: number;
  modified?: string;
  sourceApp?: string;
  sourceAttendant?: string;
}

export const SearchCustomer = () => {
  const styles = useStyles();
  const { id } = useContext(AppContext);
  const { setAlert } = useContext(AlertContext);
  const {
    behavior_list: { customer_account_handle }
  } = useContext(AssetsContext);
  const navigate = useNavigate();
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useIframeMessageHandler();

  useEffect(() => {
    // Set a short delay to prevent layout issues on navigation
    const timeout = setTimeout(() => setIsMounted(true), 100);
    return () => clearTimeout(timeout); // Cleanup on unmount
  }, []);

  const searchHandler = async ({
    email,
    phone,
    firstName,
    lastName
  }: SearchCustomerData) => {
    setIsAPICallInProgress(true);

    try {
      let customer;

      if (email) {
        customer = await getCustomerProvision(id, email.toLowerCase());
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      if (phone) {
        customer = await getCustomerProvision(id, phone);
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      if (firstName) {
        customer = await getCustomerProvision(id, firstName);
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      if (lastName) {
        customer = await getCustomerProvision(id, lastName);
        if (customer.id) {
          navigate(`/customers/${customer.id}`);
          return;
        }
      }

      // If no user is found, make fallback API calls
      const apiCalls = [];
      if (email)
        apiCalls.push(getFallbackCustomerList(id, email).catch(() => []));
      if (phone)
        apiCalls.push(getFallbackCustomerList(id, phone).catch(() => []));
      if (firstName)
        apiCalls.push(getFallbackCustomerList(id, firstName).catch(() => []));
      if (lastName)
        apiCalls.push(getFallbackCustomerList(id, lastName).catch(() => []));

      const fallbackResults = await Promise.all(apiCalls);

      const uniqueResults = fallbackResults
        .flat()
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        )[0];

      console.log('uniqueresult', uniqueResults);

      const combinedResults: Result[] = [];
      const seenIds = new Set<string>();

      try {
        // Convert uniqueResults (object) to an array
        const uniqueResultsArray: Result[] = Object.values(
          uniqueResults
        ) as Result[];

        console.log('uniqueResultsArray:', uniqueResultsArray);

        uniqueResultsArray.forEach((result, index) => {
          let isMatch = true;

          if (email) {
            if (!result.email || !result.email.includes(email)) {
              isMatch = false;
            }
          }

          if (phone) {
            if (!result.phone || !result.phone.includes(phone)) {
              isMatch = false;
            }
          }

          if (firstName) {
            if (
              !result.firstName ||
              !result.firstName.toLowerCase().includes(firstName.toLowerCase())
            ) {
              isMatch = false;
            }
          }

          if (lastName) {
            if (
              !result.lastName ||
              !result.lastName.toLowerCase().includes(lastName.toLowerCase())
            ) {
              console.log(
                `Last name mismatch. Input lastName: ${lastName}, Result lastName: ${result.lastName}`
              );
              isMatch = false;
            }
          }

          if (seenIds.has(result.id)) {
            console.log(`Duplicate ID found: ${result.id}`);
            isMatch = false;
          }

          console.log('isMatch after conditions:', isMatch);

          if (isMatch) {
            combinedResults.push(result);
            seenIds.add(result.id);
          }
        });

        console.log('combinedResults:', combinedResults);

        if (combinedResults.length > 0) {
          navigate('/customer-list', {
            state: { customers: combinedResults }
          });
          return;
        }
      } catch (e) {
        console.error('Error filtering combined results', e);
      }

      setAlert({
        text: 'User with this data was not found!',
        variation: 'error'
      });
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'Something went wrong',
          variation: 'error'
        });
      }
    } finally {
      setIsAPICallInProgress(false);
    }
  };

  const searchByIdHandler = async (accountId: string) => {
    setIsAPICallInProgress(true);
    try {
      const customer = await getCustomerProvision(id, accountId);
      if (customer.id) {
        navigate(`/customers/${customer.id}/${encodeURI(accountId)}`);
        return;
      }

      setAlert({
        text: 'User with this data was not found!',
        variation: 'error'
      });
    } catch (e) {
      const error = JSON.stringify(e.message);

      if (error === '"Network Error"') {
        setAlert({
          text: 'Check your internet and try again.',
          variation: 'error'
        });
      } else {
        setAlert({
          text: 'Something went wrong',
          variation: 'error'
        });
      }
    } finally {
      setIsAPICallInProgress(false);
    }
  };

  if (!isMounted) return null; // Do not render until mounted

  return (
    <ContentBlock hasBackButton>
      <div className={styles.header}>
        <Typography className={styles.title} component="h2">
          {customer_account_handle === CustomerAccounts.ThirdPartyAccountId
            ? 'Subject Search'
            : 'Customer Search'}
        </Typography>
        {/*TODO remove if no need, in a new design it`s missing*/}
        {/*<Typography component="h6">*/}
        {/*  {getTitle(customer_account_handle)}*/}
        {/*</Typography>*/}
      </div>
      {customer_account_handle === CustomerAccounts.ThirdPartyAccountId ? (
        <SearchByIdForm
          onSubmit={searchByIdHandler}
          isLoading={isAPICallInProgress}
        />
      ) : (
        <SearchForm onSubmit={searchHandler} isLoading={isAPICallInProgress} />
      )}
    </ContentBlock>
  );
};
