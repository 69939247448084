import { ScanStatus } from '@/constants';
import { API } from '@aws-amplify/api';
import { Attendant } from '@/pages';
import { addAttendantAuthorizationHeader } from '@/utils/add-attendant-authorization-header';

const THREE_D_ID_API = {
  BASE_URI: 'three-d-id-api'
};

//TODO setup headers not working
API.configure({
  API: {
    endpoints: [
      {
        name: THREE_D_ID_API.BASE_URI,
        endpoint: THREE_D_ID_API.BASE_URI,
        custom_header: async () => {
          const token = window.localStorage.getItem('attendantToken');
          if (token) {
            return { 'attendant-authorization': token };
          }
        }
      }
    ]
  }
});

const assetsUrl = process.env.REACT_APP_ASSETS_URL;

export const getAssetsFile = async (id: string) => {
  // add random parameter to make sure we get non-cached version
  const random = Math.random() * 10000000 + '';

  try {
    //const res = await fetch(`${assetsUrl}/${id}/${id}.json`);
    const res = await fetch(`${assetsUrl}/${id}/${id}.json?random=${random}`);
    if (res.statusText === 'Forbidden') {
      throw new Error();
    }
    return res.json();
  } catch {
    return null;
  }
};

export const getDefaultAssetsFile = async () => {
  // add random parameter to make sure we get non-cached version
  const random = Math.random() * 10000000 + '';
  try {
    //const res = await fetch(`${assetsUrl}/default/default.json`);
    const res = await fetch(
      `${assetsUrl}/default/default.json?random=${random}`
    );
    if (res.statusText === 'Forbidden') {
      throw new Error();
    }
    return res.json();
  } catch {
    return null;
  }
};

export const getApps = async () => {
  try {
    const path = '/apps';
    const myInit = {};
    //myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (e) {
    console.error(e);
  }
};

export const createCustomer = async (user: any, appId: string) => {
  const path = '/users';
  let myInit = {
    body: {
      sourceApp: appId,
      email: user.email !== undefined ? user.email : '',
      gender: user.gender !== undefined ? user.gender : '',
      phone: user.phone !== undefined ? user.phone : '',
      dateOfBirth: user.dateOfBirth !== undefined ? user.dateOfBirth : '',
      firstName: user.firstName !== undefined ? user.firstName : '',
      lastName: user.lastName !== undefined ? user.lastName : '',
      height: user.height !== undefined ? user.height : '',
      weight: user.weight !== undefined ? user.weight : ''
    }
  };
  myInit = addAttendantAuthorizationHeader(myInit);
  return await API.post(THREE_D_ID_API.BASE_URI, path, myInit);
};

export const provisionCustomer = async (
  userId: string,
  appId: string,
  userHandle: string
) => {
  try {
    const path = `/apps/${appId}/users/${userHandle}`;
    let myInit = {
      body: { userId }
    };
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.put(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    return error;
  }
};

export const getCustomerProvision = async (
  appId: string,
  userHandle: string
) => {
  try {
    const path = `/apps/${appId}/users/${userHandle}`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (e) {
    const error = JSON.stringify(e.message);

    if (error === '"Network Error"') {
      throw e;
    }
    return e;
  }
};

export const getFallbackCustomerList = async (
  appId: string,
  userHandle: string
) => {
  try {
    const path = `/apps/${appId}/users?search=${userHandle}&count=500`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (e) {
    const error = JSON.stringify(e.message);
    if (error === '"Network Error"') {
      throw e;
    }
    return [];
  }
};

export const getAgreements = async (appID: string) => {
  try {
    const path = `/agreements?entity=${appID}`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    return error;
  }
};

interface AgreementType {
  id: string;
  date: string;
  terms: string;
  name: string;
  entity: string;
}

export const addUserAgreements = async (
  userID: string,
  agreements: Array<AgreementType>,
  signature: string
) => {
  try {
    const path = `/users/${userID}/multipleAgreements`;
    let myInit = {
      body: agreements.map((agreement) => ({
        agreement: agreement.id,
        date: agreement.date,
        signature,
        status: 'Signed'
      }))
    };
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.post(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    return error;
  }
};

export const getUser = async (userId) => {
  try {
    const path = `/users/${userId}`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return ex.response;
  }
};

export const patchUser = async (user) => {
  const path = `/users/${user.id}`;
  let myInit = {
    body: user
  };
  myInit = addAttendantAuthorizationHeader(myInit);
  return await API.patch(THREE_D_ID_API.BASE_URI, path, myInit);
};

type Scan = {
  app: string;
  multiscanNum: number;
  pipelineMetadata: any;
  status: ScanStatus;
  datePartition: string;
  date: string;
  pipeline: string;
  data: {
    mesh: string;
    measures: string;
    blob: string;
  };
  parameters: {
    weight: number;
    gender: string;
    age: number;
    height: number;
  };
  sourceMetadata: any;
  id: string;
  type: string;
};

type GetUserScans = (userId: string) => Promise<Array<Scan>>;

export const getUserScans: GetUserScans = async (userId) => {
  try {
    const path = `/users/${userId}/scans`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (ex) {
    console.error('error: ' + ex.toString());
    return -1;
  }
};

export const getScan = async (scanId) => {
  try {
    const path = `/scans/${scanId}`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (e) {
    console.log(e);
  }
};

export const getAppMeasurements = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/app-measures/3`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (e) {
    console.log(e);
  }
};

export const getDXLRTWMeasurements = async (scanId, brand = null) => {
  try {
    const path = brand
      ? `/scans/${scanId}/data/${brand}`
      : `/scans/${scanId}/data/dxl-custom-measures/1`;
    return await API.post(THREE_D_ID_API.BASE_URI, path, null);
  } catch (e) {
    console.log(e);
  }
};

export const getScanImage = async (scanId) => {
  try {
    const path = `/scans/${scanId}/data/body-mesh?format=image/png`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (e) {
    console.log(e);
  }
};

export const getUserCountryCode = async () => {
  const res = await fetch(`${process.env.REACT_APP_IPAPI_URL}/country/`);
  const countryCode = await res.text();

  return countryCode.toLowerCase();
};

export const getUserIP = async () => {
  const res = await fetch(`${process.env.REACT_APP_IPAPI_URL}/ip/`);
  const IP = await res.text();

  return IP;
};

export const getAttendants = async (appId: string) => {
  try {
    const path = `/apps/${appId}/attendants`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    console.error('Error fetching attendants:', error);
    return error;
  }
};

export const getAttendant = async (appId: string, attendantId: string) => {
  try {
    const path = `/apps/${appId}/attendants/${attendantId}/token`;
    let myInit = {};
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.get(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    console.error('Error fetching attendants:', error);
    return error;
  }
};

export const updateAttendant = async (
  appId: string,
  attendantId: string,
  attendant: Attendant
) => {
  try {
    const path = `/apps/${appId}/attendants/${attendantId}`;
    let myInit = {
      body: attendant
    };
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.patch(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    console.error('Error fetching attendants:', error);
    return error;
  }
};

export const createAttendant = async (appId: string, attendant: Attendant) => {
  try {
    const path = `/apps/${appId}/attendants`;
    let myInit = {
      body: attendant
    };
    myInit = addAttendantAuthorizationHeader(myInit);
    return await API.post(THREE_D_ID_API.BASE_URI, path, myInit);
  } catch (error) {
    console.error('Error fetching attendants:', error);
    return error;
  }
};
