import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  wrapper: ({ isInIframe }: { isInIframe: boolean }) => ({
    width: '100%',
    fontFamily: 'Inter',
    gap: 'initial',
    alignItems: 'initial',
    '@media (max-width: 991px)': {
      width: '95%'
    },
    '@media (max-width: 576px)': {
      paddingTop: 20,
      width: '97%'
    },
    ...(isInIframe
      ? {
          paddingTop: '0 !important'
        }
      : {})
  }),
  contentBlock: ({ isInIframe }: { isInIframe: boolean }) => ({
    position: 'relative',
    width: 'max-content',
    maxHeight: '100vh',
    overflow: 'auto',
    maxWidth: 850,
    color: '#fff',
    background: 'rgba(56, 56, 56, 0.5)',
    backdropFilter: 'blur(50px)',
    borderRadius: 30,
    padding: '20px 30px',
    zIndex: 5,
    '@media (max-width: 991px)': {
      maxWidth: 640,
      borderRadius: 15,
      padding: '20px 25px'
    },
    '@media (max-width: 576px)': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: 15,
      padding: '15px'
    },
    ...(isInIframe
      ? {
          padding: '0 !important',
          maxWidth: 'auto !important'
        }
      : {})
  }),
  large: {
    maxWidth: '1020px!important'
  },
  content: ({ isInIframe }: { isInIframe: boolean }) => ({
    position: 'relative',
    '@media (max-width: 768px)': {
      maxHeight: 750,
      overflow: 'auto'
    },
    '@media (max-width: 576px)': {
      maxHeight: 670,
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    ...(isInIframe
      ? {
          maxHeight: 'initial !important'
        }
      : {})
  }),
  backButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    width: 20,
    height: 20,
    zIndex: 100,
    '& span, &:before': {
      position: 'absolute',
      top: 9,
      height: 2,
      width: '100%',
      display: 'inline-block',
      background: theme.colors.white
    },
    '&:before': {
      content: '""',
      transform: 'rotate(45deg)'
    },
    '& span': {
      transform: 'rotate(-45deg)'
    }
  },
  additionalInfo: {
    transform: 'translate(-100%)',
    visibility: 'hidden',
    opacity: 0,
    color: 'rgba(255, 255, 255, 0.80)',
    background: 'rgba(56, 56, 56, 0.5)',
    backdropFilter: 'blur(50px)',
    borderRadius: '0 30px 30px 0',
    padding: '20px 30px',
    margin: '25px 0',
    borderLeft: '2px solid rgba(255, 255, 255, 0.10)',
    transition: 'all 0.3s ease',
    position: 'absolute',
    '@media (max-width: 991px)': {
      padding: '20px 25px'
    },
    '@media (max-width: 576px)': {
      padding: '15px'
    }
  },
  show: {
    position: 'relative',
    transform: 'translate(0)',
    visibility: 'visible',
    opacity: 1,
    '@media (max-width: 991px)': {
      maxHeight: '90vh',
      overflowY: 'auto'
    },
    '@media (max-width: 576px)': {
      width: '93%',
      minHeight: 550,
      position: 'absolute',
      top: '50%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      zIndex: 5,
      borderLeft: 'none',
      borderRadius: '15px'
    }
  },
  showFullScreen: {
    width: '100%',
    maxWidth: '800px',
    minHeight: '80vh',
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: 5,
    borderLeft: 'none',
    borderRadius: '15px',
    '@media (max-width: 991px)': {
      maxWidth: '93%'
    }
  },
  hideButton: {
    cursor: 'pointer',
    '& svg': {
      width: 25,
      height: 25
    }
  }
}));
